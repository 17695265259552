<template>
    <div class="py-6 md:py-12 bg-gray-200 h-full">
        <div class=" mx-auto max-w-7xl px-3 lg:px-16">
            <!-- Header -->
            <div class="flex justify-between my-3 mx-8 md:mx-24 items-center">
                <div>
                    <button @click="$router.back()"
                        class="flex gap-1 text-gray-500 text-sm font-medium tracking-normal items-center hover:text-md hover:text-blue-500">

                        <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12.956 19.7361C13.2392 20.0152 13.6951 20.0118 13.9742 19.7285C14.2533 19.4453 14.2499 18.9894 13.9667 18.7103L10.0999 14.9004H19.9433C20.341 14.9004 20.6633 14.5781 20.6633 14.1804C20.6633 13.7828 20.341 13.4604 19.9433 13.4604H10.1047L13.9667 9.65525C14.2499 9.37616 14.2533 8.92029 13.9742 8.63703C13.6951 8.35378 13.2392 8.3504 12.956 8.62949L7.97049 13.5417C7.6127 13.8942 7.6127 14.4714 7.97049 14.8239L12.956 19.7361Z"
                                fill="currentColor" />
                        </svg>

                        <span>Back</span>
                    </button>

                </div>

                <div>
                    <div class="text-gray-500 font-semibold tracking-normal text-right text-base">Order ID:
                        <span class="text-gray-600 font-bold tracking-normal text-base">{{ orderDetails.invoice_id
                            }}</span>
                    </div>
                </div>

            </div>

            <!-- details -->
            <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-4 py-4">
                <div class="col-span-1 lg:col-span-4">
                    <!-- Receiver details -->
                    <div class="bg-white shadow-md rounded-md p-4 mt-4">
                        <h2 class="text-xl font-semibold text-gray-800 mb-4">Receiver Details</h2>
                        <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-2 py-4">
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Name</div>
                                <div class="text-gray-800 font-semibold">{{ orderDetails.receiver }}</div>
                            </div>
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Phone Number</div>
                                <div class="text-gray-800 font-semibold">0{{ orderDetails.receiver_phone_number }}</div>
                            </div>
                        </div>
                    </div>

                    <!-- Order details -->
                    <div class="bg-white shadow-md rounded-md p-4 mt-4">
                        <h2 class="text-xl font-semibold text-gray-800 mb-4">Order Details</h2>
                        <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-2 py-4">
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Parcel No.</div>
                                <div class="text-gray-800 font-semibold">{{ orderDetails.invoice_id }}</div>
                            </div>
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Description</div>
                                <div class="text-gray-800 font-semibold">{{ orderDetails.parcel_description }}</div>
                            </div>
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">From</div>
                                <div class="text-gray-800 font-semibold">{{ orderDetails.dropoff_agent ? 'Customer' :
                                    'Driver' }}</div>
                            </div>
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Status</div>
                                <div class="text-gray-800 font-semibold">{{ orderDetails.status }}</div>
                            </div>
                            <div class="col-span-1 lg:col-span-1">
                            </div>
                            <div class="col-span-1 lg:col-span-1">

                                <div class="flex justify-start gap-x-4">
                                    <button @click="receivePackageFromDriver"
                                        v-show="orderDetails.agent_receiving_status == 'PENDING'"
                                        class="flex justify-center bg-blue-500 text-white font-semibold tracking-normal text-base py-2 px-4 rounded-md hover:bg-blue-600">
                                        <span v-if="$store.state.isLoading">
                                            <LoadingIcon />
                                        </span>
                                        <span v-else>Receive from Driver</span>
                                    </button>
                                    <button @click="rejectFromDriver"
                                        v-show="orderDetails.agent_receiving_status == 'PENDING'"
                                        class="flex justify-center bg-red-500 text-white font-semibold tracking-normal text-base py-2 px-4 rounded-md hover:bg-red-600">
                                        <span v-if="$store.state.isLoading">
                                            <LoadingIcon />
                                        </span>
                                        <span v-else>Reject</span>
                                    </button>
                                </div>
                                <button @click="receiveDropoffPackage"
                                    v-show="orderDetails.dropoff_agent == agentDetails.agent_id && orderDetails.status == 'PACKAGE CREATED'"
                                    class="flex justify-center bg-blue-500 text-white font-semibold tracking-normal text-base py-2 px-4 rounded-md hover:bg-blue-600">
                                    <span v-if="$store.state.isLoading">
                                        <LoadingIcon />
                                    </span>
                                    <span v-else>Receive from Customer</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <!-- Give to driver -->
                    <div v-show="orderDetails.status == 'DELIVERED TO DROPOFF AGENT'"
                        class="bg-white shadow-md rounded-md p-4 mt-4">
                        <h2 class="text-xl font-semibold text-gray-800 mb-4">Give to Driver</h2>
                        <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-2 py-4">
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Driver Name: </div>
                                <div class="text-gray-800 font-semibold">
                                    <span v-if="orderDetails.dropoff_driver">{{ dropoffDriverDetails.first_name }} {{ dropoffDriverDetails.last_name }}</span>
                                    <span v-else>Pending</span>
                                </div>
                            </div>
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600"></div>
                                <button v-show="orderDetails.dropoff_driver" @click="giveToDriver"
                                    class="bg-blue-500 text-white font-semibold tracking-normal text-base py-2 px-4 rounded-md hover:bg-blue-600">
                                    <span v-if="$store.state.isLoading">
                                        <LoadingIcon />
                                    </span>
                                    <span v-else>Give package to driver</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Return package -->
                    <div v-show="orderDetails.days_stayed_at_agent > 3 || orderDetails.status === 'RETURN PACKAGE'" class="bg-white shadow-md rounded-md p-4 mt-4">
                        <h2 class="text-xl font-semibold text-gray-800 mb-4">Return Package</h2>
                        <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-2 py-4">
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Returning Driver: </div>
                                <div class="text-gray-800 font-semibold">
                                    <span v-if="orderDetails.returning_driver">{{ returningDriverDetails.first_name }} {{ returningDriverDetails.last_name }}</span>
                                    <span v-else>Pending</span>
                                </div>
                            </div>
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600"></div>
                                <button v-show="orderDetails.returning_driver  && orderDetails.days_stayed_at_agent > 3" @click="returnPackage"
                                    class="bg-red-500 text-white font-semibold tracking-normal text-base py-2 px-4 rounded-md hover:bg-red-600">Return
                                    Package</button>
                            </div>
                            <div v-if="orderDetails.status == 'RETURN PACKAGE'" class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Date Returned</div>
                                <div class="text-gray-800 font-semibold">{{ orderDetails.date_returned_by_agent.split('T')[0] }},
                                    {{ orderDetails.date_returned_by_agent.split('T')[1].split('.')[0] }}</div>
                            </div>
                        </div>
                    </div>

                    <!-- Code confirmation -->
                    <div v-show="orderDetails.status == 'DELIVERED TO AGENT'"
                        class="bg-white shadow-md rounded-md p-4 mt-4">
                        <h2 class="text-xl font-semibold text-gray-800 mb-4">Code Confirmation</h2>
                        <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-2 py-4">
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Please enter collection code: </div>
                                <input v-model="collectionCode" type="text"
                                    class="border border-gray-300 rounded-md p-2 w-full mt-3"
                                    placeholder="Enter code here" :disabled="code_confirmed == 'confirmed'">
                            </div>
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600"></div>
                                <button v-show="code_confirmed != 'confirmed'" @click="confirmCollectionCode"
                                    class="bg-blue-500 text-white font-semibold tracking-normal text-base py-2 px-4 rounded-md hover:bg-blue-600 lg:mt-9">
                                    <span v-if="$store.state.isLoading">
                                        <LoadingIcon />
                                    </span>
                                    <span v-else>Confirm</span>
                                </button>
                            </div>
                        </div>

                        <div>
                            <div v-if="code_confirmed == 'confirmed'" class="font-semibold text-green-500">Code confirmed!</div>
                            <div v-else-if="code_confirmed == 'rejected'" class="font-semibold text-red-500">Wrong code! Please try again!</div>
                        </div>
                    </div>

                    <!-- COD Details -->
                    <div v-show="code_confirmed == 'confirmed' && orderDetails.parcel_type == 'CASH ON DELIVERY'"
                        class="bg-white shadow-md rounded-md p-4 mt-4">
                        <h2 class="text-xl font-semibold text-gray-800 mb-4">Cash On Delivery</h2>
                        <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-2 py-4 items-center">
                            <div class="col-span-1 lg:col-span-2">
                                <div class="text-gray-600">COD due: <span class="text-gray-800 font-semibold">KSHS. {{ orderDetails.parcel_value }}</span></div>
                            </div>
                            <div v-show="!orderDetails.COD_Confirmation" class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Please enter your M-Pesa number: </div>
                                <input v-model="mpesa_number" type="text"
                                    class="border border-gray-300 rounded-md p-2 w-full mt-3"
                                    placeholder="Enter number here">
                                <button v-show="!orderDetails.COD_Confirmation" @click="payCOD"
                                    class="flex justify-center bg-blue-500 text-white font-semibold tracking-normal text-base py-2 px-4 rounded-md hover:bg-blue-600 lg:mt-9 mt-3">
                                    <span v-if="$store.state.isLoading"><LoadingIcon /></span>
                                    <span v-else>Pay now</span></button>
                            </div>
                            <div v-show="!orderDetails.COD_Confirmation" class="col-span-1 lg:col-span-1 ">
                                <div class="text-gray-600">Please enter your M-Pesa code: 
                                    <span><p class="text-sm text-gray-500">Use this for when you pay and it doesn't update automatically.</p></span>
                                </div>
                                <input v-model="mpesaCode" type="text"
                                    class="border border-gray-300 rounded-md p-2 w-full mt-3"
                                    placeholder="Enter code here">
                                <button v-show="!orderDetails.COD_Confirmation" @click="confirmCode"
                                    class="flex justify-center bg-blue-500 text-white font-semibold tracking-normal text-base py-2 px-4 rounded-md hover:bg-blue-600 lg:mt-4 mt-3">
                                    <span v-if="$store.state.isLoading"><LoadingIcon /></span>
                                    <span v-else>Confirm Code</span></button>
                            </div>
                            <div class="col-span-1 lg:col-span-1">
                                <div v-show="orderDetails.COD_Confirmation" class="text-gray-600">M-Pesa Code: <span class="text-gray-800 font-semibold">{{ orderDetails.COD_Confirmation_code }}</span></div>
                            </div>

                        </div>
                        
                        <div>
                            <div v-if="orderDetails.COD_Confirmation" class="text-green-500 font-semibold">COD Paid!</div>
                        </div>
                    </div>

                    <!-- Collector details -->
                    <div v-show="code_confirmed == 'confirmed'" class="bg-white shadow-md rounded-md p-4 mt-4">
                        <div v-if="orderDetails.parcel_type == 'CASH ON DELIVERY' && !orderDetails.COD_Confirmation">
                            <h2 class="text-xl font-semibold text-gray-800 mb-4">Please complete COD Payment before giving to collector</h2>
                        </div>
                        <div v-else>
                            <h2 class="text-xl font-semibold text-gray-800 mb-4">Collector By: </h2>
                            <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-2 py-4">
                                <div class="col-span-1 lg:col-span-1">
                                    <div class="text-gray-600">Name: </div>
                                </div>
                                <div class="col-span-1 lg:col-span-1">
                                    <input v-model="collector_name" type="text"
                                        class="border border-gray-300 rounded-md p-2 w-full"
                                        placeholder="Enter name here">
                                </div>
                                <div class="col-span-1 lg:col-span-1">
                                    <div class="text-gray-600">Phone Number: </div>
                                </div>
                                <div class="col-span-1 lg:col-span-1">
                                    <input v-model="collector_phone_number" type="text"
                                        class="border border-gray-300 rounded-md p-2 w-full"
                                        placeholder="Enter phone number">
                                </div>
                                <div class="col-span-1 lg:col-span-1">
                                    <div class="text-gray-600">ID Number: </div>
                                </div>
                                <div class="col-span-1 lg:col-span-1">
                                    <input v-model="collector_id_number" type="text"
                                        class="border border-gray-300 rounded-md p-2 w-full"
                                        placeholder="Enter ID number">
                                </div>
                                <div class="col-span-1 lg:col-span-1">
                                    <div class="text-gray-600"></div>
                                    <button @click="giveToCustomer"
                                        class="bg-blue-500 text-white font-semibold tracking-normal text-base py-2 px-4 rounded-md hover:bg-blue-600">
                                        <span v-if="$store.state.isLoading">
                                            <LoadingIcon />
                                        </span>
                                        <span v-else>Give to Collector</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- collected by -->
                    <div v-if="orderDetails.status == 'RECEIVED BY RECIPIENT'"
                        class="bg-white shadow-md rounded-md p-4 mt-4">
                        <h2 class="text-xl font-semibold text-gray-800 mb-4">Collected By</h2>
                        <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-2 py-4">
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Name</div>
                                <div class="text-gray-800 font-semibold">{{ orderDetails.collecting_customer_name }}
                                </div>
                            </div>
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Phone Number</div>
                                <div class="text-gray-800 font-semibold">{{
                                    orderDetails.collecting_customer_phone_number
                                    }}</div>
                            </div>
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">ID Number</div>
                                <div class="text-gray-800 font-semibold">{{
                                    orderDetails.collecting_customer_idcard_number
                                    }}</div>
                            </div>
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Date Collected</div>
                                <div class="text-gray-800 font-semibold">{{
                                    orderDetails.customer_collection_date.split('T')[0] }},
                                    {{ orderDetails.customer_collection_date.split('T')[1].split('.')[0] }}</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapMutations } from 'vuex';
import LoadingIcon from '@/components/LoadingIcon.vue'
import { MESSAGE_TEMPLATES } from '@/services/messageTemplates';
import { sendMessage } from '@/services/messagingService';

export default {
    name: "Order",
    data() {
        return {
            id: this.$route.params.id,
            isPaid: false,
            isCancelled: false,
            code_confirmed: null, // null | confirmed | rejected

            collectionCode: '',

            collector_name: '',
            collector_phone_number: '',
            collector_id_number: '',

            mpesa_number: '',
            mpesaPushResponse: [],
            mpesaCode: ''
        }
    },
    components: {
        LoadingIcon,
    },
    computed: {
        ...mapGetters(['getParcelById', 'getAgentDetails', 'getDriverById', 'getCustomerById', 'getParcelByCodConfirmationCode', 'getTransactionByReceiptNo']),

        agentDetails() {
            return this.getAgentDetails
        },

        orderDetails() {
            return this.getParcelById(this.id);
        },

        dropoffDriverDetails() {
            return this.getDriverById(this.orderDetails.dropoff_driver)
        },

        returningDriverDetails() {
            return this.getDriverById(this.orderDetails.returning_driver)
        },

        deliveryDriverDetails() {
            return this.getDriverById(this.orderDetails.driver)
        },

        customerDetails() {
            return this.getCustomerById(this.orderDetails.customer)
        },

        usedTransactionCode() {
            if (this.getParcelByCodConfirmationCode(this.mpesaCode).length) {
                return true
            } else {
                return false
            }
        },

        validTransaction() {
            const transaction = this.getTransactionByReceiptNo(this.mpesaCode)
            
            if (transaction && transaction.transaction_type == 'COD Payment') {
                return true
            } else {
                return false
            }
        },

        transaction() {
            return this.getTransactionByReceiptNo(this.mpesaCode)
        },
    },
    async created() {
        await this.$store.dispatch('fetchParcels');
        await this.$store.dispatch('fetchTransactions');
    },
    methods: {
        ...mapMutations(['setLoadingStatus']),

        async receivePackageFromDriver() {
            /**
             * For packages coming from driver
             * TODO: Details to update:
             *  ? if agentDetails.location_name == 'office':
             *      * status: 'PACKAGE IN OFFICE'
             *  ? else:
             *      * status: 'DELIVERED TO AGENT'
             *  * agent_receiving_status: 'PACKAGE RECEIVED'
             *  * date_delivered_to_agent: new Date()
             *  * updateDriverTally()
             *  * sendDeliveryMessage()
             */

            this.setLoadingStatus(true)

            let status = ''

            if (this.agentDetails.location_name == 'Office') {
                status = 'PACKAGE IN OFFICE'
            } else {
                status = 'DELIVERED TO AGENT'
            }

            const data = {
                status: status,
                agent_receiving_status: 'PACKAGE RECEIVED',
                date_delivered_to_agent: new Date()
            }

            await axios
                .put(`/packages/${this.orderDetails.id}/`, data)
                .then(async (response) => {

                    await this.updateDriverTally()

                    this.$toast.success(
                        `Package ${this.orderDetails.invoice_id} received`,
                        {
                            position: 'bottom-right',
                            duration: 3000
                        }
                    )

                    await this.sendDeliveryMessage()

                    this.$store.state.parcelsLastFetched = null
                    this.$store.dispatch('fetchParcels')

                    this.$router.push('/users/agent/incoming')
                })
                .catch((error) => {
                    this.$toast.error(
                        `Error receiving package ${this.orderDetails.invoice_id}`,
                        {
                            position: 'bottom-right',
                            duration: 3000
                        }
                    )
                })

            this.setLoadingStatus(false)
        },

        async rejectFromDriver() {
            /**
             * When the agent rejects the package
             * TODO: Details to change:
             *      * status: 'RETURN PACKAGE'
             *      * returning_driver: deliveryDriverDetails.id
             *      * date_returned_by_agent: new Date()
             *      * agent_receiving_status: 'PACKAGE REJECTED'
             */

            this.setLoadingStatus(true)

            if (confirm(`Are you sure you want to reject package ${this.orderDetails.invoice_id}?`)) {
                const data = {
                    status: 'RETURN PACKAGE',
                    returning_driver: this.deliveryDriverDetails.driver_id,
                    date_returned_by_agent: new Date(),
                    agent_receiving_status: 'PACKAGE REJECTED'
                }

                await axios
                    .put(`/packages/${this.orderDetails.id}/`, data)
                    .then((response) => {
    
                        this.$store.state.parcelsLastFetched = null
                        this.$store.dispatch('fetchParcels')
    
                        this.$toast.success(
                            `Package ${this.orderDetails.invoice_id} rejected`,
                            {
                                position: 'bottom-right',
                                duration: 3000
                            }
                        )
    
                        this.$router.push('/users/agent/incoming')
                    })
                    .catch((error) => {
    
                        this.$toast.error(
                            `Error rejecting package ${this.orderDetails.invoice_id}`,
                            {
                                position: 'bottom-right',
                                duration: 3000
                            }
                        )
                    })
            }


            this.setLoadingStatus(false)
        },

        async receiveDropoffPackage() {
            /**
             * Dropoff packages
             * TODO: Details to update:
             *      * status: 
             *          ? if dropoff_agent == agent_id: 'DELIVERED TO AGENT'
             *          ? else: 'DELIVERED TO DROPOFF AGENT'
             *      * date_received_at_dropoff_agent: new Date()
             *      * date_delivered_to_agent: new Date()
             */

            this.setLoadingStatus(true)

            let status = ''
            let data = ''

            if (this.orderDetails.dropoff_agent == this.orderDetails.agent) {
                data = {
                    status: 'DELIVERED TO AGENT',
                    date_delivered_to_agent: new Date(),
                    date_received_at_dropoff_agent: new Date()
                }
            } else {
                if (this.agentDetails.location_name == 'Office') {
                    data = {
                        status: 'PACKAGE IN OFFICE',
                        date_received_at_office: new Date()
                    }

                } else {
                    data = {
                        status: 'DELIVERED TO DROPOFF AGENT',
                        date_received_at_dropoff_agent: new Date()
                    }
                }
            }


            await axios
                .put(`/packages/${this.orderDetails.id}/`, data)
                .then(async (response) => {

                    this.$toast.success(
                        `Package ${this.orderDetails.invoice_id} received`,
                        {
                            position: 'bottom-right',
                            duration: 3000
                        }
                    )

                    if (data.status == 'PACKAGE IN OFFICE') {
                        await this.sendReceivedMessage()
                    }

                    this.$store.state.parcelsLastFetched = null
                    this.$store.dispatch('fetchParcels')

                    this.$router.push('/users/agent/incoming')
                })
                .catch((error) => {
                    this.$toast.error(
                        `Error receiving package ${this.orderDetails.invoice_id}`,
                        {
                            position: 'bottom-right',
                            duration: 3000
                        }
                    )
                })

            this.setLoadingStatus(false)
        },

        async returnPackage() {
            /**
             * * Details to update for return:
             *  ? status: 'RETURN PACKAGE'
             *  ? date_returned_by_agent: new Date()
             *  ? package_return: true
             */

            this.setLoadingStatus(true)

            const data = {
                status: 'RETURN PACKAGE',
                date_returned_by_agent: new Date(),
                package_return: true
            }

            await axios
                .put(`/packages/${this.orderDetails.id}/`, data)
                .then((response) => {

                    this.$store.state.parcelsLastFetched = null
                    this.$store.dispatch('fetchParcels')

                    this.$toast.success(
                        `Package ${this.orderDetails.invoice_id} succesfully returned`,
                        {
                            position: 'bottom-right',
                            duration: 3000
                        }
                    )

                    this.$router.push('/users/agent/pending')
                })
                .catch((error) => {

                    this.$toast.error(
                        "Something went wrong. Please try again!",
                        {
                            position: 'bottom-right',
                            duration: 3000
                        }
                    )
                })


            this.setLoadingStatus(false)
        },

        async confirmCollectionCode() {
            /**
             * * Details to update and check
             *  ? code_confirmed:
             *      * if code matches: 'confirmed'
             *      * if code doesn't match or exist: 'rejected'
             */

            this.setLoadingStatus(true)

            if (this.collectionCode == this.orderDetails.collection_code) {
                this.code_confirmed = 'confirmed'
            } else {
                this.code_confirmed = 'rejected'
            }

            this.setLoadingStatus(false)
        },

        async giveToCustomer() {
            /**
             * Give parcel to customer
             * TODO: Details to update:
             *      * status: 'RECEIVED BY RECIPIENT'
             *      * collecting_customer_name: collector_name
             *      * collecting_customer_phone_number: collector_phone_number
             *      * collecting_customer_idcard_number: colelctor_id_number
             *      * customer_collection_date: new Date()
             *      * updateAgentCommission(service)
             */

            this.setLoadingStatus(true)

            const data = {
                status: 'RECEIVED BY RECIPIENT',
                collecting_customer_name: this.collector_name,
                collecting_customer_phone_number: this.collector_phone_number,
                collecting_customer_idcard_number: this.collector_id_number,
                customer_collection_date: new Date()
            }


            await axios
                .put(`/packages/${this.orderDetails.id}/`, data)
                .then(async (response) => {

                    await this.updateAgentCommission(this.orderDetails.route_type)

                    this.$toast.success(
                        `Package ${this.orderDetails.invoice_id} given to ${this.collector_name}`,
                        {
                            position: 'bottom-right',
                            duration: 3000
                        }
                    )

                    this.$store.state.parcelsLastFetched = null
                    this.$store.dispatch('fetchParcels')

                    this.$router.push('/users/agent/pending')
                })
                .catch((error) => {
                    this.$toast.error(
                        `Error giving package ${this.orderDetails.invoice_id} to ${this.collector_name}. Please try again!`,
                        {
                            position: 'bottom-right',
                            duration: 3000
                        }
                    )
                })

            this.setLoadingStatus(false)
        },

        async giveToDriver() {
            /**
             * Give parcel to driver
             * TODO: Details to change:
             *      * status: 'PACKAGE IN TRANSIT TO OFFICE',
             *      * date_leaving_for_office: new Date()
             */

            this.setLoadingStatus(true)

            const data = {
                status: 'PACKAGE IN TRANSIT TO OFFICE',
                date_leaving_for_office: new Date()
            }

            await axios
                .put(`/packages/${this.orderDetails.id}/`, data)
                .then(async (response) => {

                    await this.updateAgentCommission('DROPOFF')

                    this.$toast.success(
                        `Package ${this.orderDetails.invoice_id} given to driver`,
                        {
                            position: 'bottom-right',
                            duration: 3000
                        }
                    )

                    this.$store.state.parcelsLastFetched = null
                    this.$store.dispatch('fetchParcels')

                    this.$router.push('/users/agent/pending')
                })
                .catch((error) => {

                    this.$toast.error(
                        `Error giving package ${this.orderDetails.invoice_id} to driver`,
                        {
                            position: 'bottom-right',
                            duration: 3000
                        }
                    )
                })

            this.setLoadingStatus(false)
        },

        async updateAgentCommission(service) {
            /**
             * Update agent commission based on service
             * @param service Service of parcel being given out
             * TODO: Details to update:
             *      * agent_commission: agent.agent_commission + commission
             *          ? CBD TO CBD: N/A
             *          ? CBD TO MTAANI: 20
             *          ? CBD TO UPCOUTNRY: 20
             *          ? MTAANI TO CBD: 25
             *          ? MTAANI TO MTAANI: 20
             *          ? MTAANI TO UPCOUNTRY: 20
             *          ? DROPOFF: 20
             */

            let commission = this.agentDetails.agent_commission

            if (service == 'CBD TO CBD') {
                commission += 0
            } else if (service == 'CBD TO MTAANI') {
                commission += 20
            } else if (service == 'CBD TO UPCOUNTRY') {
                commission += 20
            } else if (service == 'MTAANI TO CBD') {
                commission += 25
            } else if (service == 'MTAANI TO MTAANI') {
                commission += 20
            } else if (service == 'MTAANI TO UPCOUNTRY') {
                commission += 20
            } else if (service == 'DROPOFF') {
                commission += 25
            }

            const data = {
                agent_commission: commission
            }

            await axios
                .put(`/agents/${this.agentDetails.agent_id}/`, data)
                .then((response) => {
                })
                .catch((error) => {
                })

        },

        async updateDriverTally() {
            /**
             * Update driver tally
             * TODO: Details to update:
             *  * parcels_delivered: deliveryDriverDetails.parcels_delivered + 1
             */

            this.setLoadingStatus(true)

            const data = {
                parcels_delivered: this.deliveryDriverDetails.parcels_delivered + 1
            }

            await axios
                .put(`/drivers/${this.deliveryDriverDetails.driver_id}/`, data)
                .then((response) => {
                })
                .catch((error) => {
                })

            this.setLoadingStatus(false)
        },

        async payCOD() {
            /**
             * COD Payment
             * TODO: Input details:
             *      * mpesa_number
             *      * amount = orderDetails.parcel_value
             */

            this.setLoadingStatus(true)

            const phoneNumber = "254" + this.mpesa_number.slice(1);

            const data = {
                amount: this.orderDetails.parcel_value,
                phone_number: phoneNumber,
                package_id: this.orderDetails.id
            }

            await axios
                .post('mpesa/cod/stk-push/', data)
                .then((response) => {
                    const stk_response = response.data

                    if (stk_response.ResponseCode == 0) {
                        this.$toast.success(
                            "Payment request sent",
                            {
                                position: 'bottom-right',
                                duration: 3000
                            }
                        );
                    } else {
                        this.$toast.error(
                            "Payment request failed! Please try again!",
                            {
                                position: 'bottom-right',
                                duration: 3000
                            }
                        );
                    }

                    this.mpesaPushResponse.push({
                        MerchantRequestID: stk_response.MerchantRequestID,
                        CheckoutRequestID: stk_response.CheckoutRequestID,
                    });

                })
                .catch((error) => {
                    this.$toast.error(
                        "Payment request failed! Please try again!",
                        {
                            position: 'bottom-right',
                            duration: 3000
                        }
                    );
                })

            setTimeout(async () => {
                await this.verifyPayment();
            }, 15000);

        },

        async verifyPayment() {
            /**
             * Verify payment
             * TODO: Confirm payment:
             *      * COD_Confirmation
             *      * COD_Confirmation_code
             */

             await axios
                .get('mpesa/cod/callback/')
                .then(async (response) => {
                    const callbackResponses = response.data.responses;

                    for (const x in callbackResponses) {
                        const confirmMerchantRequestID = callbackResponses[x].body.Body.stkCallback.MerchantRequestID;
                        const confirmCheckoutRequestID = callbackResponses[x].body.Body.stkCallback.CheckoutRequestID;

                        if (confirmMerchantRequestID === this.mpesaPushResponse[0].MerchantRequestID && confirmCheckoutRequestID === this.mpesaPushResponse[0].CheckoutRequestID) {
                            const stkCallback = callbackResponses[x].body.Body.stkCallback;

                            if (stkCallback.ResultCode === 0) {
                                if (stkCallback.CallbackMetadata.Item[0].Value === this.orderDetails.parcel_value) {
                                    this.$toast.success(
                                        "Payment successful. Please proceed to giving customer",
                                        {
                                            position: 'bottom-right',
                                            duration: 3000
                                        }
                                    )
    
                                    let mpesa_code = stkCallback.CallbackMetadata.Item[1].Value
    
                                    await this.updatePackageDetails(mpesa_code)
                                    await this.updateCodBalance(this.orderDetails.parcel_value)
                                    await this.sendCodMessage()

                                    this.$store.state.parcelsLastFetched = null
                                    this.$store.dispatch('fetchParcels')

                                } else {
                                    this.$toast.error(
                                        "Payment failed! Amount mismatch",
                                        {
                                            position: 'bottom-right',
                                            duration: 3000
                                        }
                                    )
                                }
                            } else {
                                this.$toast.error(
                                    "Payment failed! Please try again!",
                                    {
                                        position: 'bottom-right',
                                        duration: 3000
                                    }
                                )
                            }
                        }
                        
                        this.$store.state.transactionsLastFetched = null
                        this.$store.dispatch('fetchTransactions');
                        
                    }
                })
                .catch((error) => {
                    this.$toast.error(
                        "Something went wrong! Please try again!",
                        {
                            position: 'bottom-right',
                            duration: 3000
                        }
                    )
                
                })

            this.setLoadingStatus(false)
        },

        // confirm Mpesa code
        async confirmCode() {
            this.setLoadingStatus(true);

            if (this.validTransaction) {
                if (this.usedTransactionCode) {
                    this.$toast.error(
                        'This code has already been used',
                        {
                            position: 'bottom-right',
                            duration: 3000
                        }
                    )
                } else {
                    
                    if (this.transaction.amount == this.orderDetails.parcel_value) {
                        await this.updatePackageDetails(this.mpesaCode);
                        await this.updateCodBalance(this.orderDetails.parcel_value);
                        await this.sendCodMessage()

                        this.$store.state.parcelsLastFetched = null
                        this.$store.dispatch('fetchParcels')
                    } else {
                        this.$toast.error(
                            "The amount paid does not match!",
                            {
                                position: 'bottom-right',
                                duration: 3000
                            }
                        )
                    }
                }
            } else {
                this.$toast.error(
                    "Invalid transaction code!",
                    {
                        position: 'bottom-right',
                        duration: 3000
                    }
                )
            }

            this.setLoadingStatus(false);
        },

        async updateCodBalance(addAmount) {
            /**
             * Update COD balance
             * @param addAmount: amount to be added.
             * @data customer_id: customerDetails.customer_id
             *      CODbalance: customerDetails.COD_balance + addAmount
             */

            const data = {
                customer_id: this.customerDetails.customer_id,
                CODbalance: this.customerDetails.COD_balance + addAmount
            }

            await axios
                .put(`/customercodbalance/update/`, data)
                .then((response) => {
                    this.$store.state.customerLastFetched = null
                    this.$store.dispatch('fetchCustomerDetails', this.customerDetails.customer_id)
                })
                .catch((error) => {
                    this.$toast.error(
                        "Something went wrong. Please try again!",
                        {
                            position: 'bottom-right',
                            duration: 3000
                        }
                    )
                })
        },

        async updatePackageDetails(mpesa_code) {
            /**
             * @param mpesa_code
             * Update package details
             * TODO: Details to update:
             *      * COD_Confirmation
             *      * COD_Confirmation_code = mpesa_code
             */

            const data ={
                COD_Confirmation: true,
                COD_Confirmation_code: mpesa_code
            }

            await axios
                .put(`/packages/${this.id}/`, data)
        },

        async sendDeliveryMessage() {
            /**
             * Send message to customer
             * * TEMPLATE: PACKAGE_DELIVERED
             * @data receiver: orderDetails.receiver,
             *      invoice_id: orderDetails.invoice_id,
             *      parcel_description: orderDetails.parcel_description,
             *      agent_collection_shop_name: agentDetails.collection_shop_name,
             *      agent_location: agentDetails.location_name,
             *      collection_code: orderDetails.collection_code,
             *      agent_phone_number: agentDetails.phone_number
             * 
             * @data phoneNumber = orderDetails.receiver_phone_number
             */

            let messageTemplate = MESSAGE_TEMPLATES.PACKAGE_DELIVERED

            let messageData = {
                receiver: this.orderDetails.receiver,
                invoice_id: this.orderDetails.invoice_id,
                parcel_description: this.orderDetails.parcel_description,
                agent_collection_shop_name: this.agentDetails.collection_shop_name,
                agent_location: this.agentDetails.location_name,
                collection_code: this.orderDetails.collection_code,
                agent_phone_number: this.agentDetails.phone_number
            }

            let phoneNumber = this.orderDetails.receiver_phone_number

            try {
                await sendMessage(phoneNumber, messageTemplate, messageData)
            } catch (error) {
                console.error("Something went wrong while sending message: ", error);
                
            }
        },

        async sendReceivedMessage() {
            /**
             * Send a message to the customer that their package has been received
             * * TEMPLATE: PACKAGE_RECEIVED
             * @data receiver: orderDetails.received,
             *      invoice_id: orderDetails.invoice_id,
             *      parcel_description: orderDetails.parcel_description
             * 
             * @data phoneNumber: orderDetails.receiver_phone_number
             */

             this.setLoadingStatus(true)

            const messageTemplate = MESSAGE_TEMPLATES.PACKAGE_RECEIVED

            const messageData = {
                receiver: this.orderDetails.receiver,
                invoice_id: this.orderDetails.invoice_id,
                parcel_description: this.orderDetails.parcel_description
            }

            const phoneNumber = this.orderDetails.receiver_phone_number

            try {
                await sendMessage(phoneNumber, messageTemplate, messageData)
                
            } catch (error) {
                // console.error("Something went wrong while sending message: ", error);
            }

            this.setLoadingStatus(false)
        },

        async sendCodMessage() {
            /**
             * Send message to customer
             * * TEMPLATE: COD_PAID
             * @data customer_name: customerDetails.first_name + ' ' + customerDetails.last_name,
             *      invoice_id: orderDetails.invoice_id,
             * 
             * @data phoneNumber = customerDetails.phone_number
             */

            let messageTemplate = MESSAGE_TEMPLATES.COD_PAID

            let messageData = {
                customer_name: this.customerDetails.first_name + ' ' + this.customerDetails.last_name,
                invoice_id: this.orderDetails.invoice_id,
                parcel_value: this.orderDetails.parcel_value
            }

            let phoneNumber = this.customerDetails.phone_number

            try {
                await sendMessage(phoneNumber, messageTemplate, messageData)
            } catch (error) {
                console.error("Something went wrong while sending message: ", error);
            }
        }


    },
}
</script>