<template>
    <div class="py-6 md:pb-12 h-full bg-gray-200">
        
        <!-- Main data -->
        <div class="mx-auto max-w-7xl px-3 lg:px-4">
            <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-4 py-4">
                <div class="col-span-1 lg:col-span-4">
                    <!-- Welcome details -->
                    <div class="bg-white shadow-md rounded-md p-4">
                        <h2 class="text-xl font-semibold text-gray-800 mb-4">Dashboard</h2>
                        <p class="text-gray-600">Welcome back, <span class="font-semibold">{{ adminDetails.first_name }} {{ adminDetails.last_name }}</span>!</p>
                    </div>
                    
                    <div class="grid grid-cols-1 gap-x-8 gap-y-6 md:grid-cols-2 lg:grid-cols-3 py-4 items-stretch">
                        <!-- New users -->
                        <div class="col-span-1 lg:col-span-1">
                            <div class="bg-white shadow-md rounded-md p-4 text-center hover:shadow-xl">
                                <h2 class="text-xl font-semibold text-gray-900 mb-4">New Customers:</h2>
                                <p class="text-3xl font-bold px-5 text-center">
                                    <span v-if="newCustomers.total">{{ newCustomers.total }}</span>
                                    <span v-else> - </span>
                                </p>
                                <div class="text-left">
                                    <p class="text-gray-600 text-lg">Individual: <span class="font-semibold text-xl">{{ newCustomers.individualAccounts }}</span></p>
                                    <p class="text-gray-600 text-lg">Business: <span class="font-semibold text-xl">{{ newCustomers.businessAccounts }}</span></p>
                                </div>
                                <div class="py-2">
                                    <p class=""><span class="text-green-500">-%</span> from previous</p>
                                </div>
                            </div>
                        </div>

                        <!-- order details -->
                        <div class="col-span-1 lg:col-span-1">
                            <div class="bg-white shadow-md rounded-md p-4 text-center hover:shadow-xl">
                                <h2 class="text-xl font-semibold text-gray-900 mb-4">Orders:</h2>
                                <p class="text-3xl font-bold px-5 text-center p-8">
                                    <span v-if="newOrders">{{ newOrders }}</span>
                                    <span  v-else> - </span>
                                </p>
                                <div class="py-2">
                                    <p class=""><span class="text-green-500">-%</span> from previous</p>
                                </div>
                            </div>
                        </div>

                        <!-- Returned orders -->
                        <div class="col-span-1 lg:col-span-1">
                            <div class="bg-white shadow-md rounded-md p-4 text-center hover:shadow-xl">
                                <h2 class="text-xl font-semibold text-gray-900 mb-4">Returned Orders:</h2>
                                <p class="text-3xl font-bold px-5 text-center p-8">
                                    <span v-if="returnedParcels">{{ returnedParcels }}</span>
                                    <span  v-else> - </span>
                                </p>
                                <div class="py-2">
                                    <p class=""><span class="text-green-500">-%</span> from previous</p>
                                </div>
                            </div>
                        </div>

                        <!-- cancelled orders -->
                        <div class="col-span-1 lg:col-span-1">
                            <div class="bg-white shadow-md rounded-md p-4 text-center hover:shadow-xl">
                                <h2 class="text-xl font-semibold text-gray-900 mb-4">Cancelled Orders:</h2>
                                <p class="text-3xl font-bold px-5 text-center p-8">
                                    <span v-if="cancelledParcels">{{ cancelledParcels }}</span>
                                    <span  v-else> - </span>
                                </p>
                                <div class="py-2">
                                    <p class=""><span class="text-green-500">-%</span> from previous</p>
                                </div>
                            </div>
                        </div>

                        <!-- revenue -->
                        <div class="col-span-1 lg:col-span-1">
                            <div class="bg-white shadow-md rounded-md p-4 text-center hover:shadow-xl">
                                <h2 class="text-xl font-semibold text-gray-900 mb-4">Revenue:</h2>
                                <p class="text-3xl font-bold px-5 text-center p-8">{{ totalRevenue }}</p>
                                <div class="py-2">
                                    <p class=""><span class="text-green-500">-%</span> from previous</p>
                                </div>
                            </div>
                        </div>

                        <!-- COD Revenue -->
                        <div class="col-span-1 lg:col-span-1">
                            <div class="bg-white shadow-md rounded-md p-4 text-center hover:shadow-xl">
                                <h2 class="text-xl font-semibold text-gray-900 mb-4">COD:</h2>
                                <p class="text-3xl font-bold px-5 text-center p-8">{{ totalCODValue }}</p>
                                <div class="py-2">
                                    <p class=""><span class="text-green-500">-%</span> from previous</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="grid grid-cols-1 gap-x-8 gap-y-6 md:grid-cols-2 lg:grid-cols-4 py-4 items-stretch">
                        <!-- top service -->
                        <div class="cols-span-2 lg:col-span-2">
                            <div class="bg-white shadow-md rounded-md p-4 text-center hover:shadow-xl">
                                <h2 class="text-xl font-semibold text-gray-800 mb-4">Top Services:</h2>
                                <div class="py-2 px-12 flex justify-between">
                                    <p class="">{{ topServices.service }}</p>
                                    <p> : </p>
                                    <span class="text-green-500 text-2xl items-center">{{ topServices.count }}</span>
                                </div>
                            </div>
                        </div>

                        <!-- top area -->
                        <div class="cols-span-2 lg:col-span-2">
                            <div class="bg-white shadow-md rounded-md p-4 text-center hover:shadow-xl">
                                <h2 class="text-xl font-semibold text-gray-800 mb-4">Top Areas:</h2>
                                <div class="py-2 px-12 flex justify-between">
                                    <p class="">{{ topArea.area }}</p>
                                    <p> : </p>
                                    <span class="text-green-500 text-2xl items-center">{{ topArea.count }}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapMutations } from 'vuex';

    export default {
        name: 'adminDashboard',
        async created() {
            await this.$store.dispatch('fetchCustomers')
            await this.$store.dispatch('fetchAllParcels')
        },
        computed: {
            ...mapGetters(['getAdminDetails', 'getCustomers', 'getPaidParcels']),

            adminDetails() {
                return this.getAdminDetails
            },

            customers() {
                return this.getCustomers
            },

            newCustomers() {
                const today = new Date().toDateString()
                let total = 0
                let individualAccounts = 0
                let businessAccounts = 0

                for (const i in this.customers) {
                    const created = new Date(this.customers[i].date_joined).toDateString()

                    if (today === created) {
                        total++

                        if (this.customers[i].account_type == "INDIVIDUAL") {
                            individualAccounts++
                        } else if (this.customers[i].account_type == "BUSINESS") {
                            businessAccounts++
                        }
                    }
                }

                return {total, individualAccounts, businessAccounts}
            },

            parcels() {
                return this.getPaidParcels
            },

            newOrders() {
                const today = new Date().toDateString()
                let orders = 0

                for (const i in this.parcels) {
                    const created = new Date(this.parcels[i].created).toDateString()
                    if (today === created) {
                        orders++
                    }
                }

                return orders
            },

            returnedParcels() {
                const today = new Date().toDateString()
                let returned = 0

                for (const i in this.parcels) {
                    const created = new Date(this.parcels[i].created).toDateString()
                    if (today === created && this.parcels[i].status === "RETURN PACKAGE") {
                        returned++
                    }
                }

                return returned
            },

            cancelledParcels() {
                const today = new Date().toDateString()
                let cancelled = 0

                for (const i in this.parcels) {
                    const created = new Date(this.parcels[i].created).toDateString()
                    if (today === created && this.parcels[i].status === "PACKAGE CANCELLED") {
                        cancelled++
                    }
                }

                return cancelled
            },

            topServices() {
                const serviceCount = {}

                for (const i in this.parcels) {
                    const service = this.parcels[i].route_type

                    if (serviceCount[service]) {
                        serviceCount[service]++
                    } else {
                        serviceCount[service] = 1
                    }
                }

                let maxService = ''
                let maxCount = 0

                for (const service in serviceCount) {
                    if (serviceCount[service] > maxCount) {
                        maxService = service
                        maxCount = serviceCount[service]
                    }
                }

                return { service: maxService, count: maxCount }
            },

            topArea() {
                const areaCount = {}

                for (const i in this.parcels) {
                    const area = this.parcels[i].destination.toUpperCase()

                    if (areaCount[area]) {
                        areaCount[area]++
                    } else {
                        areaCount[area] = 1
                    }
                }

                let maxArea = ''
                let maxCount = 0

                for (const area in areaCount) {
                    if (areaCount[area] > maxCount) {
                        maxArea = area
                        maxCount = areaCount[area]
                    }
                }

                return { area: maxArea, count: maxCount }
            },

            totalCODValue() {
                const today = new Date().toDateString()

                let codValue = 0

                for (const i in this.parcels) {
                    const created = new Date(this.parcels[i].created).toDateString()
                    
                    if (today === created) {
                        if (this.parcels[i].parcel_type === "CASH ON DELIVERY" && this.parcels[i].COD_Confirmation) {
                            codValue = codValue + +this.parcels[i].parcel_value
                        }
                    }

                }

                return codValue
            },

            totalRevenue() {
                const today = new Date().toDateString()

                let revenue = 0

                for (const i in this.parcels) {
                    const created = new Date(this.parcels[i].created).toDateString()

                    if (today === created) {
                        const order = this.parcels[i]
                        
                        if (order.pickup_location) {
                            revenue += 0
                        }
    
                        if (order.route_type === "CBD TO CBD") {
                            revenue += 80
                        } else if (order.route_type === "CBD TO MTAANI") {
                            revenue += 100
                        } else if (order.route_type === "MTAANI TO CBD") {
                            revenue += 100
                        } else if (order.route_type === "MTAANI TO MTAANI") {
                            revenue += 210
                        } else if (order.route_type === "MTAANI TO UPCOUNTRY") {
                            revenue += 380
                        } else if (order.route_type === "CBD TO MTAANI") {
                            revenue += 100
                        }
                    }

                }

                return revenue
            }

        },
    }
</script>