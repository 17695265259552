<template>
  <div class="my-8 md:mx-24 w-auto bg-gray-200 px-4 py-8">
    <h1
      class="text-3xl font-bold leading-tight tracking-tight text-gray-900 md:text-4xl text-center mb-6"
    >
      Our Agents
    </h1>

    <div v-if="!$store.state.isLoading">
      <div v-if="agentsByLocation.length">
        <div
          v-for="(location, index) in agentsByLocation"
          :key="index"
          class="mb-6"
        >
          <h2 class="text-xl font-semibold text-gray-900 mb-2">
            {{ location.name }}
          </h2>
          <ul class="list-disc pl-5">
            <li
              v-for="agent in location.agents"
              :key="agent.agent_id"
              class="text-gray-700 mb-1"
            >
              <span class="font-semibold">{{ agent.collection_shop_name }}</span>
              - {{ agent.location_description }}
              <router-link
                to="#"
                class="text-blue-500 hover:text-orange-500 ml-2"
              >
                (View Details)
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div v-else>
        <p class="text-gray-700 text-center">Please login or register to view our agents</p>
      </div>
    </div>

    <div v-else class="flex justify-center">
      <LoadingIcon />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LoadingIcon from '@/components/LoadingIcon.vue';

export default {
  name: 'OurAgents',
  components: {
    LoadingIcon,
  },
  computed: {
    ...mapGetters(['getAgents']),
    agentsByLocation() {
      const locations = {};
      this.getAgents.forEach((agent) => {
        if (agent.active) {
          if (!locations[agent.location_name]) {
            locations[agent.location_name] = {
              name: agent.location_name,
              agents: [],
            };
          }
          locations[agent.location_name].agents.push(agent);
        }
      });
      return Object.values(locations);
    },
  },
  async created() {
    await this.$store.dispatch('fetchAgents');
  },
};
</script>
