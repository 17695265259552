import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import './assets/tailwind.css'
import store from './store'
import axios from 'axios'
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css'
import * as Sentry from '@sentry/vue';

axios.defaults.baseURL = 'https://sub.timelydelivery.net'

// Sentry.init({
//   app,
//   dsn: "https://2b0d82cdc32d474152eb9f53aac8a466@o4507882869489664.ingest.de.sentry.io/4507940336762960",
//   integrations: [
//         Sentry.browserTracingIntegration({ router }),
//         Sentry.replayIntegration(),
//         Sentry.browserProfilingIntegration(),
// ],
//       // Tracing
//       tracesSampleRate: 1.0, //  Capture 100% of the transactions
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ["localhost", /^https:\/\/timelydelivery\.net\//],
//       // Session Replay
//       replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//       replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//       // Profiling
//       profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
// });


createApp(App)
  .use(store)
  .use(router, axios)
  .use(ToastPlugin)
  .mount('#app')